<script setup lang="ts">
const props = defineProps({
	href: {
		type: String,
		default: null,
	},
	to: {
		type: String,
	},
	openInNewWindow: {
		type: Boolean,
		default: false,
	},
});

const el = useTemplateRef('edit-link-button');

const handleClick = (event) => {
	event.stopPropagation();
	event.preventDefault();

	const closestDataPath = event.target.closest("[data-path]")?.dataset.path;

	parent.postMessage(
		{
			action: "getLink",
			payload: {
				closestDataPath,
				value: {
					href: props.href,
					to: props.to,
				},
			},
		},
		"*",
	);

  useGizmoStore().getLinkCallback = (result) => {
    el.value.dispatchEvent(
        new CustomEvent("contentChanged", {
          detail: {
            oldValue: {
              href: props.to || props.href
            },
            unknownProp: true,
            ...result
          },
          bubbles: true,
          composed: true,
        })
    );
  };
	return false;
};
</script>

<template>
	<div class="edit-link-button" ref="edit-link-button">
		<TnButton
			icon-only="link"
			@click="handleClick"
			size="xs"
		/>
	</div>
</template>

<style scoped lang="scss">
.edit-link-button {
	position: absolute;
	top: $spacing-m;
	right: $spacing-m;
	z-index: 1337;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.2s ease;
}
</style>
